<template>
    <div class="overview-content flex-column jc-sb">
        <div class="overview-content__item flex-row ai-fs jc-sb">
           <p class="overview-content__label default-text">Address</p>
           <p class="overview-content__data default-text" :class="{'blur': isBlured}">{{property?.address}}</p>
        </div>
        <div class="overview-content__item flex-row ai-c jc-sb">
           <p class="overview-content__label default-text">Property type</p>
           <p class="overview-content__data default-text">{{property.propertyType ? property?.propertyType : getEpcData['property-type'] ? getEpcData['property-type'] : NA}}</p>
        </div>
        <div class="overview-content__item flex-row ai-c jc-sb">
           <p class="overview-content__label default-text">Floor area</p>
           <p class="overview-content__data default-text">{{property.propertySize ? property?.propertySize + ' sqm' : getEpcData['total-floor-area'] ? getEpcData['total-floor-area'] + ' sqm' : NA}}</p>
        </div>
        <div class="overview-content__item flex-row ai-c jc-sb">
           <p class="overview-content__label default-text">Bedrooms</p>
           <p class="overview-content__data default-text">{{property.beds ? property?.beds : NA}}</p>
        </div>
        <div class="overview-content__item flex-row ai-c jc-sb">
           <p class="overview-content__label default-text">Carbon footprint</p>
           <p class="overview-content__data default-text">{{getEpcData['co2-emissions-current'] ? getEpcData['co2-emissions-current'] + 't of CO2' : NA}}</p>
        </div>
        <div class="overview-content__item flex-row ai-c jc-sb">
           <p class="overview-content__label default-text">Energy rating</p>
           <p class="overview-content__data default-text">{{getEpcData['current-energy-efficiency']}}&nbsp; |&nbsp; {{getEpcData['current-energy-rating']}}</p>
        </div>
    </div>
</template>
<script>
const NA = 'N/A';

export default {
  props: ['property', 'isBlured'],
  data() {
    return {
      NA
    };
  },
  computed: {
    getEpcData() {
      return JSON.parse(this.property?.epcData);
    }
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
.overview-content {
  height: 100%;
  gap: 0;
  &__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
  &__data {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: rgba(12, 15, 74, 0.5);
    text-align: right;
  }
  &__data.blur {
    filter: blur(3px);
 }
}
</style>
