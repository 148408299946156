<template>
    <div class="text-status flex-row ai-c jc-c" :class="{'transparent': phaseStatus.id === ProjectStageStatusEnum.STAGE_COMPLETE}">
      <!-- <p class="text-status__status-description default-text" :class="{ 'half-opacity': statusType === 'not-started', 'yellow': statusType === 'paid' || statusType === 'completed' }">
        {{ statusType === 'paid' ? 'PAID' : statusType === 'completed' ? 'COMPLETED' : statusType === 'in-progress' ? 'IN PROGRESS' : 'NOT STARTED'}}
      </p> -->

      <p class="text-status__status-description default-text" :class="{ 'half-opacity': phaseStatus.id === ProjectStageStatusEnum.NOT_STARTED, 'yellow': phaseStatus.id === ProjectStageStatusEnum.STAGE_COMPLETE || phaseStatus.id === ProjectStageStatusEnum.IN_PROGRESS_POKED }">
        <!-- {{ phaseStatus.name }} -->

        {{
          phaseStatus.id === ProjectStageStatusEnum.PAYMENT_IN_PROCESS ? 'PROCESSING PAYMENT' :
          phaseStatus.id === ProjectStageStatusEnum.IN_PROGRESS_POKED ? 'COMPLETED' :
          phaseStatus.id === ProjectStageStatusEnum.STAGE_COMPLETE ? 'PAID' :
          phaseStatus.id === ProjectStageStatusEnum.IN_PROGRESS ? 'IN PROGRESS' : 'NOT STARTED'
        }}
      </p>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { ProjectStageStatusEnum } from '@/core/packages/shared-library';

export default defineComponent({
  //   statusType: paid, not-started, completed, in-progress //
  props: ['phaseStatus'],

  data() {
    return {
      ProjectStageStatusEnum
    };
  },
  created() {
  },

});
</script>
<style lang="scss">
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.text-status {
    width: 125px;
    min-width: 125px;
    max-width: 125px;
    background: rgba(12, 15, 74, 0.05);
    border-radius: 8px;
    padding: 5px 0;
    box-sizing: border-box;

    &__status-description {
      font-size: 10px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1.149999976158142px;
      text-align: center;
      text-transform: uppercase;
    }
    &__status-description.half-opacity {
      color: rgba(12, 15, 74, 0.5);
    }
    &__status-description.yellow {
      color: #FFA500;
    }
}
.text-status.transparent {
    background: transparent;
}
@include media-sm-max-width() {
  .text-status {
    width: 115px;
    min-width: 115px;
    max-width: 115px;
  }
}
</style>
