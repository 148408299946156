<template>
    <div class="date-time-display flex-column">
        <div class="date-time-display__wrapper flex-row ai-c jc-sb" v-if="!isNotEditable">
          <p class="date-time-display__text default-text">Date & Time</p>
          <ActionItemComponent
            label="Edit"
            icon="pen-filled-yellow-icon.svg"
            fontColor="#FFA500"
            @call-to-action="onEdit"/>
        </div>
        <DateAndTimeItem
          v-for="(dateAndTime, dateIndex) in datesAndTimes" :key="dateIndex"
          @on-time-slot-select="onTimeSlotSelect($event)"
          @on-delete-time-slot="onDeleteTimeSlot($event)"
          @on-delete-date="onDeleteDate($event)"
          :selectedDateAndTimeIndex="selectedDateAndTimeIndex"
          :dateIndex="dateIndex"
          :dateAndTime="dateAndTime"
          :isNotEditable="isNotEditable"
          :isClickable="isClickable"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import DateAndTimeItem from '@/core/components/common/calendar/DateAndTimeItem.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';

export default defineComponent({
  components: {
    ActionItemComponent,
    DateAndTimeItem
  },
  emits: ['on-time-slot-select', 'on-delete-time-slot', 'on-delete-date', 'on-edit'],
  props: ['datesAndTimes', 'isNotEditable', 'isClickable', 'selectedDateAndTimeIndex'],
  methods: {
    onTimeSlotSelect(dateAndTimeIndex) {
      this.$emit('on-time-slot-select', dateAndTimeIndex);
    },
    onDeleteTimeSlot(dateAndTimeIndex) {
      this.$emit('on-delete-time-slot', dateAndTimeIndex);
    },
    onDeleteDate(dateIndex) {
      this.$emit('on-delete-date', dateIndex);
    },
    onEdit() {
      this.$emit('on-edit');
    }
  },
});
</script>
<style lang="scss" scoped>
.date-time-display {
    width: 100%;
    &__header * p {
        font-size: 16px !important;
    }
    &__wrapper {
        width: 100%;
    }
    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 156.25% */
        letter-spacing: 0.25px;
    }
}
</style>
