import { defineComponent } from 'vue';
import BalanceView from '@/core/components/ui/BalanceView.vue';
import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
export default defineComponent({
    components: {
        BalanceView
    },
    props: ['projectStages', 'projectAccountBalance'],
    data() {
        return {
            requiredDescription: ''
        };
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            const { projectStages } = this;
            const hasRequiredDeposit = [];
            for (let i = 0; i < projectStages.length; i++) {
                const projectStage = projectStages[i];
                const type = projectStage.stageType === ProjectStageTypeEnum.MATERIALS ? 'Materials' : `Phase ${i + 1}`;
                if (projectStages[i].requiredDeposit) {
                    hasRequiredDeposit.push(type);
                }
            }
            this.requiredDescription = hasRequiredDeposit.join(', ');
        }
    }
});
