import { CopyDocument } from '@element-plus/icons';
export default {
    components: {
        CopyDocument,
    },
    emits: ['set-screen'],
    data() {
        return {
            paymentDetailsData: [
                {
                    label: 'Beneficiary',
                    data: 'Amanda Po',
                },
                {
                    label: 'Sort code',
                    data: '20-20-20',
                },
                {
                    label: 'Account number',
                    data: '376567547',
                },
                {
                    label: 'Reference',
                    data: '#1234ABCD',
                },
                {
                    label: 'Total amount',
                    data: '£1,800.00',
                },
            ]
        };
    },
    methods: {
        setScreen(screen) {
            this.$emit('set-screen', screen);
        },
        async copyText(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.$notify.success({
                    title: 'Copied',
                    message: `${text}`,
                });
            }
            catch ($e) {
                this.$notify.error({
                    title: 'Cannot copy',
                    message: 'This text is cannot be copied.',
                });
            }
        }
    },
};
