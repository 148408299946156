import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AttachmentsBoxComponent from '@/core/components/common/box-cards/AttachmentsBoxComponent.vue';
// import BudgetBoxComponent from '@/core/components/common/box-cards/BudgetBoxComponent.vue';
import DescriptionBoxComponent from '@/core/components/common/box-cards/DescriptionBoxComponent.vue';
import DetailsBoxComponent from '@/core/components/common/box-cards/DetailsBoxComponent.vue';
import ImageCarouselBoxComponent from '@/core/components/common/box-cards/ImageCarouselBoxComponent.vue';
// import MembersBoxComponent from '@/core/components/common/box-cards/MembersBoxComponent.vue';
import RequestMeetingBoxComponent from '@/core/components/common/box-cards/RequestMeetingBoxComponent.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import EscrowDrawerComponent from '@/modules/project-details-children/components/drawer/EscrowDrawerComponent.vue';
import MobileDepositStatusComponent from '@/modules/project-details-children/components/MobileDepositStatusComponent.vue';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { PROJECTS_CACHE } from '@/store/modules/attachment-cache/constants';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';
import { PROJECT_QUOTES_STORE } from '../../../store/modules/project-quotes';
export default defineComponent({
    name: 'project-detail-overview',
    components: {
        ImageCarouselBoxComponent,
        DetailsBoxComponent,
        DescriptionBoxComponent,
        AttachmentsBoxComponent,
        // MembersBoxComponent,
        // BudgetBoxComponent,
        MobileDepositStatusComponent,
        EscrowDrawerComponent,
        RequestMeetingBoxComponent,
        Loader
    },
    data() {
        return {
            project: null,
            property: null,
            projectAttachments: [],
            isAttachmentRequestComplete: true,
            projectImageAttachments: [],
            isNoticeHidden: false,
            userCustomerAccount: null,
            userCustomerCurrentBalance: 0,
            userCustomerAccountDetails: null,
            status: '',
        };
    },
    beforeUnmount() {
        this.setEnableProjectEscrowSidebar(false);
    },
    async created() {
        await this.initialize();
        await this.loadProjectAttachments();
    },
    computed: {
        ...mapGetters(['isProjectOwner', 'isTradesperson', 'isLoading']),
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(PROJECTS_STORE, ['getOpenEscrowDrawer']),
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject',
            'getProjectStatusLabel'
        ]),
        ...mapGetters(PROJECT_QUOTES_STORE, ['getQuoteStatusLabel']),
        // getProjectStatus() {
        //   let projectStatus;
        //   if (this.isProjectOwner) {
        //     console.log(this.currentSelectedProject, 'this.currentSelectedProject');
        //     projectStatus = this.getProjectStatusLabel(this.currentSelectedProject);
        //   } else if (this.isTradesperson && this.currentSelectedProject?.confirmedQuote) {
        //     projectStatus = this.getQuoteStatusLabel(this.currentSelectedProject?.confirmedQuote);
        //   }
        //   return projectStatus;
        // },
        previewImages() {
            return this.projectImageAttachments.map((attachment) => attachment.src);
        },
    },
    watch: {
        currentSelectedProject: {
            immediate: true,
            deep: true,
            handler() {
                this.getProjectStatus();
            }
        }
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(PROPERTY_STORE, ['getProperty']),
        ...mapActions(PROJECTS_STORE, ['setOpenEscrowDrawer']),
        ...mapActions(PROJECTS_STORE, [
            'getProjectByRefId',
            'setEnableProjectEscrowSidebar',
            'setHasGetProjectFinished',
            'setIsProjectReadyToWork',
            'setCurrentSelectedProject',
            'setProjectAccountBalance',
        ]),
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments', 'resetCacheEntity']),
        ...mapActions(USERS_STORE, ['getUserCustomerAccountDetails']),
        async initialize() {
            const { params } = this.$route;
            const { projectRefId } = params;
            const workableProjectStatus = [
                ProjectJobStatusEnum.QUOTE_CONFIRMED,
                ProjectJobStatusEnum.IN_PROGRESS,
                ProjectJobStatusEnum.COMPLETED
            ];
            this.setEnableProjectEscrowSidebar(false);
            await this.getProjectByRefId(projectRefId).then(async (response) => {
                this.project = response;
                this.setHasGetProjectFinished(true);
                this.setCurrentSelectedProject(response);
                this.getProjectAccountBalance();
                if (workableProjectStatus.includes(response.projectJobStatus.id)) {
                    this.setEnableProjectEscrowSidebar(true);
                    this.setIsProjectReadyToWork(true);
                }
                else {
                    this.setIsProjectReadyToWork(false);
                }
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error occured. Please reload the page'
                });
            })
                .finally(() => {
                setTimeout(() => {
                    this.setIsLoading(false);
                }, 500);
            });
            const { propertyId } = this.project;
            if (propertyId) {
                this.property = await this.getProperty(propertyId);
            }
        },
        getProjectStatus() {
            let projectStatus;
            if (this.isProjectOwner) {
                console.log(this.currentSelectedProject, 'this.currentSelectedProject');
                projectStatus = this.getProjectStatusLabel(this.currentSelectedProject);
            }
            else if (this.isTradesperson && this.currentSelectedProject?.confirmedQuote) {
                console.log(this.currentSelectedProject, 'this.currentSelectedProject');
                projectStatus = this.getQuoteStatusLabel(this.currentSelectedProject?.confirmedQuote);
            }
            this.status = projectStatus;
        },
        async getProjectAccountBalance() {
            const { currentSelectedProject } = this;
            if (currentSelectedProject?.projectAccount) {
                await this.getUserCustomerAccountDetails({
                    userId: currentSelectedProject.projectCreator.id,
                    accountId: currentSelectedProject?.projectAccount.accountId
                })
                    .then((response) => {
                    const { availableBalance } = response;
                    this.setProjectAccountBalance(availableBalance);
                })
                    .catch(() => { });
            }
        },
        async loadProjectAttachments() {
            const { currentSelectedProject } = this;
            const { projectAttachments } = currentSelectedProject;
            this.isAttachmentRequestComplete = false;
            this.resetCacheEntity(PROJECTS_CACHE);
            await this.getAttachments({
                name: PROJECTS_CACHE,
                attachments: projectAttachments,
            })
                .then((values) => {
                const newValues = values.filter(Boolean);
                if (newValues.length) {
                    this.projectAttachments = newValues.map((item) => {
                        const attachment = this.getProjectAttachmentId(item.attachment);
                        return {
                            ...item,
                            id: attachment[0]?.id
                        };
                    });
                    this.projectImageAttachments = this.projectAttachments.filter((item) => {
                        return this.isImageAttachment(item.originalName);
                    });
                }
                this.isAttachmentRequestComplete = true;
            })
                .catch(() => { })
                .finally(() => { this.isAttachmentRequestComplete = true; });
        },
        getProjectAttachmentId(attachment) {
            const { project } = this;
            return project.projectAttachments.filter((item) => {
                return item.attachment === attachment;
            });
        },
        isImageAttachment(filename) {
            const extension = filename.split('.')[filename.split('.').length - 1];
            return ImageFileTypes.includes(extension.toLowerCase());
        },
        hideNotice() {
            this.isNoticeHidden = true;
        }
    }
});
