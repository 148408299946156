<template>
    <DefaultCardSlot :showDropdownMenu="false" :cardHeight="cardHeight">
        <PropertyDetailsOverviewContent :property="property" :isBlured="isBlured"/>
    </DefaultCardSlot>
</template>
<script>
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import PropertyDetailsOverviewContent from '@/modules/project-post-overview/components/PropertyDetailsOverviewContent.vue';

export default {
  components: {
    DefaultCardSlot,
    PropertyDetailsOverviewContent
  },
  props: ['property', 'cardHeight', 'isBlured'],
};
</script>
<style lang="scss" scoped>
</style>
