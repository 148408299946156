<template>
    <BoxSlotComponent title="Meeting" :edit="false">
      <div class="meeting flex-row ai-c jc-c">
        <el-image
        fit="contain"
        :src="require(`@/assets/images/calendar-empty-state-icon.png`)"
        ></el-image>
        <div class="meeting__wrapper flex-column ai-c jc-c">
          <p class="meeting__label default-text">{{ isTradesperson ? TP_LABEL : PO_LABEL }}</p>
          <el-button type="primary" class="meeting__button flex-row ai-c jc-c default-text" round @click="openModal">{{ isTradesperson ? TP_BUTTON : PO_BUTTON }}</el-button>
        </div>

        <!-- MODAL -->
        <RequestMeetingModal :show="showRequestMeetingModal" @on-cancel="setRequestMeetingModal(false)"/>
        <ViewMeetingRequestModal
          :show="showViewMeetingRequestModal"
          :schedules="schedules"
          @on-load-schedules="setUserSchedules(true)"
          @open-schedule-form="openScheduleForm"
          @on-cancel="setViewMeetingReqeustModal(false)"/>
      </div>
    </BoxSlotComponent>
</template>
<script>
import _ from 'lodash';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import BoxSlotComponent from '@/core/components/common/box-cards/BoxSlotComponent.vue';
import RequestMeetingModal from '@/core/components/common/modals/RequestMeetingModal.vue';
import ViewMeetingRequestModal from '@/core/components/common/modals/ViewMeetingRequestModal.vue';
import { CALENDAR_EVENT_TYPE_PROJECT } from '@/core/constants';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';
import { USERS_STORE } from '@/store/modules/users';

const TP_LABEL = 'You can request to schedule an in-person or video meeting';
const TP_BUTTON = 'Request meeting';
const PO_LABEL = 'Trades contractor has requested a meeting';
const PO_BUTTON = 'View';

export default defineComponent({
  components: {
    BoxSlotComponent,
    RequestMeetingModal,
    ViewMeetingRequestModal
  },
  data() {
    return {
      TP_LABEL,
      TP_BUTTON,
      PO_LABEL,
      PO_BUTTON,

      showRequestMeetingModal: false,
      showViewMeetingRequestModal: false,

      loadingSchedules: false,
      schedules: [],
    };
  },
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
    ...mapGetters(USERS_STORE, ['user']),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(USER_CALENDAR_STORE, ['getSchedules', 'setOriginalSchedule']),

    setRequestMeetingModal(state) {
      this.showRequestMeetingModal = state;
    },
    setViewMeetingReqeustModal(state) {
      this.showViewMeetingRequestModal = state;
    },
    async initialize() {
      await this.setUserSchedules();
    },
    async setUserSchedules(closeForm = false) {
      const userId = this.user.id;
      const projectRefId = this.$route.params?.projectRefId;
      const options = { type: CALENDAR_EVENT_TYPE_PROJECT, id: projectRefId };
      const params = {
        userId,
        options
      };
      this.loadingSchedules = true;

      await this.getSchedules(params).then((response) => {
        this.schedules = response.data;
        this.setOriginalSchedule(_.cloneDeep(response.data));
      }).catch((e) => {
        this.$notify.error({
          title: 'Error in retrieving schedules',
          message: e.data.message || 'Error occurred when retrieving schedules.',
        });
      })
        .finally(() => {
          this.loadingSchedules = false;

          if (closeForm) {
            this.setRequestMeetingModal(false);
            this.setViewMeetingReqeustModal(false);
          }
        });
    },
    async openModal() {
      await this.initialize();
      if ((this.isTradesperson && this.schedules.length) || this.isProjectOwner) {
        this.openMeetingOverview();
      }
      if (this.isTradesperson && !this.schedules.length) {
        this.openScheduleForm();
      }
    },
    openScheduleForm() {
      this.setViewMeetingReqeustModal(false);
      this.setRequestMeetingModal(true);
    },
    openMeetingOverview() {
      this.setRequestMeetingModal(false);
      this.setViewMeetingReqeustModal(true);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

  .meeting {
    height:194px;
    max-height: 194px;
    position: relative;
    padding:1.5rem;
    box-sizing: border-box;
    border-radius: 20px;
    border:none;
    background-color: #fff;
    gap: 2.5rem;
    box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);

    &__label {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.4000000059604645px;
        text-align: center;
        color: rgba(12, 15, 74, 0.5);
        width: 70%;
    }

    &__button {
        background: #FFF;
        box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1);
        border-radius: 8px !important;
        color: #4F55F0;
        border: none;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: center;
        padding: 0 3rem;
        margin-bottom: 1rem;

        &:hover {
            background: #FFF;
            color: #4F55F0;
            transition: all .2s ease-in-out;
        }
    }
  }

  @include media-xs-max-width() {
    .meeting {
      &__label {
        font-size: 12px;
        line-height: 18px;
        width: 90%;
      }

      &__button {
        font-size: 12px;
      }
    }
  }
</style>
