<template>
  <div class="phase-payment flex-column">
    <div class="phase-payment__header flex-row ai-c">
      <p class="phase-payment__header-title default-text">Select payment amount</p>
      <el-icon :size="15" color="rgba(12, 15, 74, 0.3)" class="cursor-pointer"><InfoFilled/></el-icon>
    </div>
    <div class="phase-payment__content flex-column">
      <!-- FULL PROJECT DEPOSIT -->
      <div class="phase-payment__item flex-row ai-c jc-sb">
        <div class="phase-payment__item-row flex-row ai-c">
          <el-checkbox v-if="!hasCompletedPayment" v-model="isFullDepositRequired" @change="onCheckFullDeposit">
            <p class="phase-payment__data default-text">
              Full Project - <span class="phase-payment__amount">{{ $filters.formatToCurrency(bill.totalPaidOrUnpaid) }}</span>
            </p>
          </el-checkbox>

          <p v-else class="phase-payment__data default-text">
              Fully paid - <span class="phase-payment__amount">{{  $filters.formatToCurrency(bill.totalPaidOrUnpaid) }}</span>
          </p>
        </div>
        <div class="phase-payment__item-row flex-row ai-c">
          <el-icon :size="13" color="#0C0F4A"><ArrowRightBold/></el-icon>
        </div>
      </div>
      <!-- PHASE DEPOSIT - LOOP THIS ITEM -->
      <div class="phase-payment__item flex-row ai-c jc-sb" v-for="(phase, index) in phases" :key="index">
        <div class="phase-payment__item-row flex-row ai-c">
          <!-- display icon if status is not started -->
          <IconStatusComponent :phaseStatus="phase.status.id" v-if="phase.inProgress"/>
          <!-- display checkbox if status is not started -->
          <el-checkbox v-if="!phase.inProgress" v-model="phase.hasSelected">
            <p class="phase-payment__data default-text">
              {{ phase.isMaterial ? 'Materials' : `Phase ${index + 1}` }} - <span class="phase-payment__amount">{{
                  $filters.formatToCurrency(phase.amount)
              }}</span>
            </p>
          </el-checkbox>
          <p class="phase-payment__data default-text" v-if="phase.inProgress">
            {{ phase.isMaterial ? 'Materials' : `Phase ${index + 1}` }} - <span class="phase-payment__amount">{{
              $filters.formatToCurrency(phase.amount)
            }}</span>
          </p>
          <IconStatusComponent v-if="phase.hasRequiredDeposit && !phase.inProgress" :hasRequiredDeposit="phase.hasRequiredDeposit"/>
        </div>
        <div class="phase-payment__item-row flex-row ai-c">
          <TextStatusComponent :phaseStatus="phase.status" />
          <el-icon :size="13" color="#0C0F4A"><ArrowRightBold/></el-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import IconStatusComponent from '@/core/components/common/status/IconStatusComponent.vue';
import TextStatusComponent from '@/core/components/common/status/TextStatusComponent.vue';
import { ProjectStageStatusEnum } from '@/core/packages/shared-library';
import { PROJECT_STAGES_STORE } from '@/store/modules/project-stages';

export default defineComponent({
  components: {
    ArrowRightBold,
    IconStatusComponent,
    TextStatusComponent
  },

  props: ['projectStages', 'bill'],

  emits: ['remove-main-button'],

  data() {
    return {
      fullDeposit: '',
      isFullDepositRequired: false,
      paidStatuses: [ProjectStageStatusEnum.IN_PROGRESS, ProjectStageStatusEnum.STAGE_COMPLETE],

      ProjectStageStatusEnum,
      phases: [],

      hasCompletedPayment: false
    };
  },
  computed: {
    hasntMadePayments() {
      return this.data.every(({ paid }) => !paid);
    }
  },
  async created() {
    this.initialize();
  },

  watch: {
    phases: {
      immediate: true,
      deep: true,
      handler() {
        this.initializeStoreValues();
      }
    },
  },

  methods: {
    ...mapActions(PROJECT_STAGES_STORE, [
      'setSelectedStagesToPay',
    ]),

    initializeStoreValues() {
      const toStore = [];

      this.phases.forEach((phase) => {
        if (phase.hasSelected && !phase.inProgress) {
          toStore.push(phase);
        }
      });

      this.setSelectedStagesToPay(toStore);
    },

    initialize() {
      this.normalizePhases();
    },

    normalizePhases() {
      const { projectStages } = this;
      let requiredDepositCount = 0;

      this.phases = projectStages.map((projectStage) => {
        const {
          id, stageCostAndServiceFee, stageCost, projectStageServiceFee, isMaterial, projectStageStatus, requiredDeposit
        } = projectStage;
        const phaseStatus = { id: projectStageStatus.id, name: projectStageStatus.name };

        const phase = {
          id,
          amount: stageCostAndServiceFee,
          isMaterial,
          status: phaseStatus,
          hasSelected: requiredDeposit || false,
          hasRequiredDeposit: requiredDeposit,
          stageCost,
          projectStageServiceFee,
          projectStageStatus: { ...projectStageStatus },
          inProgress: !([ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED].includes(phaseStatus.id))
        };

        if (phase.inProgress) {
          requiredDepositCount += 1;
        }

        return phase;
      });

      // if (!requiredDepositCount) {
      //   this.phases[0].hasRequiredDeposit = true;
      // }

      if (this.phases.length === requiredDepositCount) {
        this.hasCompletedPayment = true;
        this.$emit('remove-main-button');
      }
    },

    onCheckFullDeposit(value) {
      const { phases } = this;

      this.isFullDepositRequired = value;
      this.phases = phases.map((phase) => {
        const { inProgress } = phase;
        let hasSelected = this.isFullDepositRequired;

        if (inProgress) {
          hasSelected = true;
        }

        return { ...phase, hasSelected };
      });
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.phase-payment {
    gap: 2rem;

    &__header-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.25px;
        margin-top: 50px;
    }

    &__content {
        gap: 1rem;
    }

    &__item-row {
        gap: .5rem;
    }

    &__data {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.25px;
        white-space: nowrap;

        span {
           color: rgba(12, 15, 74, 0.5);
        }
    }

    // &__status {
    //     width: 110px;
    //     min-width: 110px;
    //     max-width: 110px;
    //     background: rgba(12, 15, 74, 0.05);
    //     border-radius: 8px;
    //     padding: 5px 0;
    //     box-sizing: border-box;
    // }
    // &__status.transparent {
    //     background: transparent;
    // }
    // &__status-description {
    //     font-size: 10px;
    //     font-weight: 600;
    //     line-height: 16px;
    //     letter-spacing: 1.149999976158142px;
    //     text-align: center;
    //     text-transform: uppercase;
    // }
    // &__status-description.half-opacity {
    //     color: rgba(12, 15, 74, 0.5);
    // }
    // &__status-description.yellow {
    //     color: #FFA500;
    // }
}
.el-checkbox {
    height: auto;
}

@include media-sm-max-width() {
  .phase-payment {
    &__data {
      font-size: 12px;
    }
  }
}
</style>
