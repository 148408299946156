<template>
    <div class="collapsible-box flex-column jc-c" :class="{'collapse': isCollapse || !isCollapsible}">
       <div class="collapsible-box__collapse-action-wrapper cursor-pointer" @click="toggleArrow" v-if="isCollapsible">
           <el-icon :size="13" color="rgba(12, 15, 74, 0.5)" v-if="!isCollapse"><ArrowDownBold/></el-icon>
           <el-icon :size="13" color="rgba(12, 15, 74, 0.5)" v-else><ArrowUpBold/></el-icon>
       </div>
        <slot name="header"></slot>
       <div v-if="(isCollapse && isCollapsible) || (!isCollapsible && !noContent)">
         <slot name="content"></slot>
       </div>
    </div>
</template>
<script>
import { ArrowDownBold, ArrowUpBold } from '@element-plus/icons';

export default {
  components: {
    ArrowUpBold,
    ArrowDownBold
  },
  props: {
    isCollapsible: {
      type: Boolean,
      default: true
    },
    noContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    toggleArrow() {
      this.isCollapse = !this.isCollapse;
    }
  },
};
</script>
<style lang="scss" scoped>
.collapsible-box {
    position: relative;
    background-color: #FFF;
    width: 100%;
    height: 60px;
    padding: 1.5rem;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
    border-radius: 20px;

    &__collapse-action-wrapper {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
    }
}
.collapsible-box.collapse {
  height: auto;
  transition: height 0.5s ease-in-out;
  -webkit-transition: height 0.5s ease-in-out;
  -moz-transition: height 0.5s ease-in-out;
  -ms-transition: height 0.5s ease-in-out;
  -o-transition: height 0.5s ease-in-out;
}
</style>
