<template>
    <div class="mobile-deposit-status flex-row ai-c jc-c show-in-mobile">
       <el-icon :size="17" color="#FFA500"><WarningFilled/></el-icon>
       <p class="mobile-deposit-status__description default-text" v-if="!projectStarted">Deposit required for {{requiredPhases.join(' & ')}} to start the project.</p>
       <p class="mobile-deposit-status__description default-text" v-if="projectStarted && duePhases.length">{{duePhases.join(' & ')}} deposit payment due.</p>
       <el-icon :size="17" color="#0C0F4A"><ArrowRightBold/></el-icon>
    </div>
</template>
<script>
import { ArrowRightBold, WarningFilled } from '@element-plus/icons';
import { mapGetters } from 'vuex';

import { ProjectStageStatusEnum } from '@/core/packages/shared-library';
import CurrencyFilter from '@/filters/currency.filter';
import { PROJECTS_STORE } from '@/store/modules/projects';

export default {
  components: {
    WarningFilled,
    ArrowRightBold
  },
  emits: ['hide-notice'],
  data() {
    return {
      phases: [],
      projectStages: [],
      hasCompletedDeposit: false,
      duePhases: [],
      requiredPhases: [],
      projectStarted: false
    };
  },
  async created() {
    this.initialize();
  },
  computed: {
    ...mapGetters(PROJECTS_STORE, [
      'currentSelectedProject'
    ]),
  },
  methods: {
    initialize() {
      const { currentSelectedProject } = this;
      const { confirmedQuote } = currentSelectedProject;

      this.projectStages = confirmedQuote.projectStages;

      this.normalizePhases();
    },
    normalizePhases() {
      const { projectStages } = this;
      let requiredDepositCount = 0;

      this.phases = projectStages.map((projectStage, index) => {
        const {
          id, stageCostAndServiceFee, isMaterial, projectStageStatus, requiredDeposit, subTotal
        } = projectStage;
        const phaseStatus = { id: projectStageStatus.id, name: projectStageStatus.name };

        let amount = '';

        if (isMaterial) {
          amount = CurrencyFilter.formatToCurrency(subTotal);
        } else {
          amount = CurrencyFilter.formatToCurrency(stageCostAndServiceFee);
        }
        const phase = {
          id,
          isMaterial,
          hasSelected: requiredDeposit || false,
          hasRequiredDeposit: requiredDeposit,
          inProgress: !([ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED].includes(phaseStatus.id)),
          tag: isMaterial ? `Materials (${amount})` : `Phase ${index + 1} (${amount})`
        };

        if (phase.inProgress) {
          this.projectStarted = true;
          requiredDepositCount += 1;
        }
        if (!phase.inProgress && !phase.hasRequiredDeposit) {
          this.duePhases.push(phase.tag);
        }
        if (!phase.inProgress && phase.hasRequiredDeposit) {
          this.requiredPhases.push(phase.tag);
        }

        return phase;
      });

      if (this.phases.length === requiredDepositCount) {
        this.hasCompletedDeposit = true;
        this.$emit('hide-notice');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-deposit-status {
    border: 3px solid #FFA500;
    filter: drop-shadow(0px 0px 10px rgba(42, 42, 42, 0.02));
    border-radius: 8px;
    cursor: pointer;
    padding: .5rem 1rem;
    box-sizing: border-box;

    &__description {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.23999999463558197px;
    }
}
</style>
