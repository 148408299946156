<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="view-meeting-request flex-column jc-sb">
        <div class="view-meeting-request__container flex-column">
          <section class="view-meeting-request__header flex-row ai-c jc-sb">
              <div class="flex-row ai-c">
                <el-icon :size="17" class="cursor-pointer" @click="backToScheduleList(LIST)" v-if="screenType === DETAILS"><ArrowLeftBold/></el-icon>
                <h2 class="view-meeting-request__title default-text">Meeting request</h2>
              </div>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </section>
          <section class="view-meeting-request__body">
            <!-- LIST OF SCHEDULES -->
            <div class="view-meeting-request__list-wrapper flex-column" v-if="screenType === LIST">
              <p class="view-meeting-request__text bold default-text" v-if="isProjectOwner && schedules.length">
                One or more trades contractors have requested a meeting. Please select from below to see more details</p>
              <p class="view-meeting-request__text bold default-text" v-else>No meeting requests found.</p>
              <el-scrollbar>
                <div class="view-meeting-request__scrollbar-content flex-column">
                  <CollapsibleBox class="cursor-pointer" style="padding: .7rem 1rem;"
                    :isCollapsible="false" :noContent="true"
                    v-for="(list, index) in schedules" :key="index" @click="seeScheduleDetails(DETAILS, list, index)">
                    <template #header>
                      <GlobalDynamicHeader :hasEndCustomComponent="true" :hasStartCustomComponent="true">
                        <template #start-custom-component>
                          <LabeledThumbnail :img="list?.user?.userPublicProfile?.profileImage" :label="`Preference: ${transformUnderscoreToSpace(list?.preference)}`"/>
                        </template>
                        <template #end-custom-component>
                          <p v-if="isProjectOwner" class="view-meeting-request__status-text default-text" :class="{'green': hasConfirmedTimeSlotInSchedule(index)}">
                            {{ hasConfirmedTimeSlotInSchedule(index) ? scheduleConstants.STATUS_CONFIRMED : 'PENDING' }}
                          </p>
                          <el-icon :size="15"><ArrowRightBold/></el-icon>
                        </template>
                      </GlobalDynamicHeader>
                    </template>
                  </CollapsibleBox>
                </div>
              </el-scrollbar>
            </div>
            <!-- SELECTED SCHEDULE DETAILS -->
            <div class="view-meeting-request__wrapper flex-column jc-sb" v-if="hasData && screenType === DETAILS" style="height: 100%;">
              <div class="flex-column">
                  <div class="flex-row ai-c jc-sb">
                    <LabeledThumbnail
                      :img="getOriginalScheduleByIndex(selectedScheduleDataIndex)?.user?.userPublicProfile?.profileImage"
                      :label="getOriginalScheduleByIndex(selectedScheduleDataIndex)?.userWorkstation?.name" v-if="isProjectOwner"/>
                    <p v-if="isProjectOwner" class="view-meeting-request__status-text default-text" :class="{'green': hasConfirmedTimeSlotInSchedule(selectedScheduleDataIndex)}">
                      {{ hasConfirmedTimeSlotInSchedule(selectedScheduleDataIndex) ? scheduleConstants.STATUS_CONFIRMED : 'PENDING' }}
                    </p>
                  </div>
                  <div class="flex-column" style="margin-bottom: 1rem;">
                    <p v-if="isTradesperson" class="view-meeting-request__text bold default-text">
                      {{ `You requested a ${transformUnderscoreToSpace(selectedScheduleData?.preference)} meeting with the following:` }}
                      <ul>
                        <li v-for="(recipient, index) in selectedScheduleData?.recipients" :key="index">
                          {{ recipient.email }} - <span class="view-meeting-request__status-text default-text"
                            :class="{'green': recipient.status}">{{ recipient.status || scheduleConstants.STATUS_PENDING }}</span>
                        </li>
                      </ul>
                    </p>
                    <p v-else class="view-meeting-request__text bold default-text">
                      {{ `Requested a meeting (${transformUnderscoreToSpace(selectedScheduleData?.preference)}) at your project location` }}
                    </p>
                    <p class="view-meeting-request__text bold default-text" v-if="isProjectOwner">
                        Please select if any of the below date & time work for you.
                    </p>
                    <p class="view-meeting-request__text bold default-text" v-if="isProjectOwner">
                        Alternatively, suggest your availability.
                    </p>
                  </div>
                <DateAndTimeOverview
                  @on-time-slot-select="onTimeSlotSelect($event, selectedScheduleData, selectedScheduleDataIndex)"
                  :datesAndTimes="selectedScheduleData?.schedule"
                  :selectedDateAndTimeIndex="selectedDateAndTimeIndex"
                  :isNotEditable="true"
                  :isClickable="isProjectOwner ? true : false"/>
               </div>
               <div class="view-meeting-request__wrapper flex-column ai-c" v-if="isProjectOwner">
                 <Button :isActive="isReadyToSubmit" buttonText="Confirm" :loading="isComfirming" @handle-click="onConfirm"/>
                 <p class="view-meeting-request__text bold active default-text cursor-pointer" @click="routeToProjectChat">Suggest my availability</p>
               </div>
            </div>
            <div class="view-meeting-request__wrapper flex-column ai-c" v-if="screenType === LIST && isTradesperson">
              <Button :isActive="true" buttonText="Send a new request" @handle-click="openScheduleForm" />
            </div>
          </section>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from '@vue/runtime-core';
// import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import DateAndTimeOverview from '@/core/components/common/calendar/DateAndTimeOverview.vue';
import GlobalDynamicHeader from '@/core/components/common/header/GlobalDynamicHeader.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import CollapsibleBox from '@/core/components/ui/CollapsibleBox.vue';
import LabeledThumbnail from '@/core/components/ui/LabeledThumbnail.vue';
import { CALENDAR_EVENT_TYPE_PROJECT } from '@/core/constants';
import scheduleConstants from '@/core/constants/calendar';
import StringHelper from '@/core/helpers/string.helper';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';
import { USERS_STORE } from '@/store/modules/users';

const DETAILS = 'details';
const LIST = 'list';

export default defineComponent({
  components: {
    BaseModal,
    ArrowRightBold,
    DateAndTimeOverview,
    Button,
    ArrowLeftBold,
    CollapsibleBox,
    GlobalDynamicHeader,
    LabeledThumbnail
  },
  emits: ['on-cancel', 'open-schedule-form', 'on-load-schedules'],
  props: ['show', 'schedules'],
  data() {
    return {
      scheduleConstants,
      DETAILS,
      LIST,
      screenType: LIST,

      selectedDateAndTimeIndex: null,

      selectedScheduleData: null,
      selectedScheduleDataIndex: null,

      isComfirming: false,
      currentSchedules: []
    };
  },
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
    ...mapGetters(USER_CALENDAR_STORE, ['dateWithTime', 'originalSchedule']),
    ...mapGetters(USERS_STORE, ['user']),

    hasData() {
      return this.dateWithTime != null && this.dateWithTime !== undefined;
    },
    isReadyToSubmit() {
      return this.selectedDateAndTimeIndex && this.selectedDateAndTimeIndex != null;
    }
  },
  created() {
  },
  methods: {
    ...mapActions(USER_CALENDAR_STORE, ['setDateWithTime', 'createOrUpdateScheduleRequest']),

    cancel() {
      this.$emit('on-cancel');
    },
    seeScheduleDetails(type, data, index) {
      this.selectedScheduleData = data;
      this.selectedScheduleDataIndex = index;
      this.setScreen(type);
    },
    backToScheduleList(type) {
      this.selectedScheduleData = null;
      this.selectedScheduleDataIndex = null;
      this.selectedDateAndTimeIndex = null;
      this.setScreen(type);
    },
    setScreen(type) {
      this.screenType = type;
    },
    onTimeSlotSelect(dateAndTimeIndex, schedule, selectedScheduleIndex) {
      const { dateIndex, timeIndex } = dateAndTimeIndex;
      const selectedTimeSlot = schedule.schedule[dateIndex]?.timeslots[timeIndex];

      // Reset the status property of the previously selected time slot (if any)
      if (this.selectedDateAndTimeIndex) {
        const { dateIndex: prevDateIndex, timeIndex: prevTimeIndex } = this.selectedDateAndTimeIndex;
        const prevSelectedTimeSlot = schedule.schedule[prevDateIndex]?.timeslots[prevTimeIndex];
        if (prevSelectedTimeSlot) {
          delete prevSelectedTimeSlot.status;
        }
      }

      // Check if there are confirmed time slots in the schedule
      if (this.hasConfirmedTimeSlotInSchedule(selectedScheduleIndex)) {
        // Set status to empty string for all time slots
        for (const slot of schedule.schedule) {
          for (const timeSlot of slot.timeslots) {
            timeSlot.status = '';
          }
        }
      }

      // Update the status property of the newly selected time slot to CONFIRMED
      if (selectedTimeSlot) {
        selectedTimeSlot.status = scheduleConstants.STATUS_CONFIRMED;
        schedule.title = '';

        // Remove dateCreated and dateUpdated properties
        delete schedule.dateCreated;
        delete schedule.dateUpdated;
      }

      this.selectedDateAndTimeIndex = dateAndTimeIndex;
    },
    routeToProjectChat() {
      this.$router.push({ name: 'chat' });
    },
    openScheduleForm() {
      this.$emit('open-schedule-form');
    },
    async onConfirm() {
      const formData = this.selectedScheduleData;
      const userId = this.user.id;
      const projectRefId = this.$route.params?.projectRefId;
      const options = { type: CALENDAR_EVENT_TYPE_PROJECT, id: projectRefId };

      // we add the confirmee details
      if (this.isProjectOwner) {
        formData.recipients = formData.recipients.map((recipient) => {
          if (recipient.email === StringHelper.removeEmailAlias(this.user.email)) {
            return {
              ...recipient,
              status: scheduleConstants.STATUS_CONFIRMED
            };
          }

          return { ...recipient };
        });

        delete formData.userWorkstation;
        delete formData.user;
      }

      const params = {
        userId,
        formData,
        options
      };

      this.isComfirming = true;

      await this.createOrUpdateScheduleRequest(params)
        .then(async (response) => {
          if (response) {
            this.$emit('on-load-schedules');
            this.$notify.success({
              title: 'Meeting confirmed.',
              message: 'Meeting is now confirmed.',
            });
          }
        })
        .catch((e) => {
          this.$notify.error({
            title: 'Meeting confirmation failed',
            message: e.data.message || 'Error occurred when trying to confirm the meeting.',
          });
        })
        .finally(() => {
          this.isComfirming = false;
        });
    },
    transformUnderscoreToSpace(inputString) {
      if (!inputString) return '';
      return inputString.replace(/_/g, ' ');
    },
    getOriginalScheduleByIndex(index) {
      return this.originalSchedule[index];
    },
    hasConfirmedTimeSlotInSchedule(index) {
      return this.originalSchedule[index].schedule.some((slot) => slot.timeslots.some((timeSlot) => timeSlot.status === scheduleConstants.STATUS_CONFIRMED));
    },
  },
});
</script>
<style lang="scss" scoped>
 .view-meeting-request {
    min-width: 500px;
    max-width: 500px;
    min-height: 700px;
    position: relative;
    height: 700px;

    &__status-text {
      font-weight: 700;
      font-size: 14px;
      color: #FFA500;
    }
    &__status-text.green {
      color: green;
    }
    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }

    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 156.25% */
        letter-spacing: 0.25px;
    }

    &__text.bold {
        font-weight: 700;
    }

    &__item {
      padding: 1rem 1.5rem;
      border-radius: 20px;
      background: #FFF;
      box-shadow: 0px 0px 20px 0px rgba(12, 15, 74, 0.10);
    }

    &__list-wrapper {
      height: 100%;
      max-height: calc(100% - 3rem);
    }
    &__scrollbar-content {
      padding: 1rem;
      box-sizing: border-box;
    }
 }
 .active {
   color: #FFA500;
 }
</style>
