import { LocationFilled, Picture } from '@element-plus/icons';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        LocationFilled,
        Picture,
    },
    props: ['postcode', 'previewImages', 'projectAttachments', 'projectImageAttachments', 'projectRefId'],
    data() {
        return {
            url: '',
            projectQuoteAttachments: [],
        };
    },
    created() {
    },
    computed: {},
    methods: {}
});
