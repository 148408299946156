import { ArrowLeftBold, CircleCloseFilled, InfoFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import StringHelper from '@/core/helpers/string.helper';
import { ProjectStageStatusEnum, ProjectStageTypeEnum } from '@/core/packages/shared-library';
import AutoPaymentComponent from '@/modules/project-details-children/components/escrowSidebar/AutoPaymentComponent.vue';
import EscrowWalletOverviewComponent from '@/modules/project-details-children/components/escrowSidebar/EscrowWalletOverviewComponent.vue';
import ManualPaymentComponent from '@/modules/project-details-children/components/escrowSidebar/ManualPaymentComponent.vue';
import { PAYMENTS_STORE } from '@/store/modules/payments';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECT_STAGE_DEPOSITS_STORE } from '@/store/modules/project-stage-deposits';
import { PROJECT_STAGES_STORE } from '@/store/modules/project-stages';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        EscrowWalletOverviewComponent,
        ArrowLeftBold,
        InfoFilled,
        AutoPaymentComponent,
        ManualPaymentComponent,
        CircleCloseFilled
    },
    data() {
        return {
            //   screen = overview, auto-payment, manual-payment
            screen: 'overview',
            projectAccountBalance: 0,
            billInformation: {
                totalAmountToPay: 0,
                totalStageCost: 0,
                paymentReference: '',
                vat: 0,
                totalAndVat: 0,
                totalPaidOrUnpaid: 0
            },
            projectStages: [],
            requiredStagesToPay: [],
            project: null,
            unpaidStatuses: [ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED],
            isFilled: false,
            removeMainButton: false,
            isPaymentProcessing: false,
            isMountReady: false
        };
    },
    watch: {
        selectedStagesToPay: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value.length) {
                    this.setBillInformation();
                }
            }
        },
        hasGetProjectFinished: {
            immediate: true,
            deep: true,
            handler(value) {
                this.isMountReady = value;
                if (value) {
                    this.initialize();
                }
            }
        }
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(PROJECTS_STORE, [
            'hasGetProjectFinished',
            'isProjectReadyToWork',
            'currentSelectedProject'
        ]),
        ...mapState(PAYMENTS_STORE, {
            paymentForm: (state) => state.paymentForm,
        }),
        ...mapGetters(PROJECT_STAGES_STORE, ['selectedStagesToPay']),
    },
    created() {
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'getUserCustomerAccount',
            'getUserCustomerAccountDetails',
        ]),
        ...mapActions(PAYMENTS_STORE, ['getPaymentProviders']),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'setSelectedProjectJobStatus', 'setOpenEscrowDrawer']),
        ...mapActions(PROJECT_QUOTES_STORE, ['getTotalStageCost', 'calculateServiceFee']),
        ...mapActions(PROJECT_STAGE_DEPOSITS_STORE, [
            'createProjectStageDeposit',
            'approveReleaseProjectStageDeposit',
            'createAllProjectStageDeposit'
        ]),
        async initialize() {
            const { currentSelectedProject } = this;
            const { confirmedQuote } = currentSelectedProject;
            if (confirmedQuote) {
                this.getProjectAccountBalance();
                this.setSelectedProjectJobStatus(currentSelectedProject.projectJobStatus);
                // this.initiateProjectStagePusherNotification();
                if (confirmedQuote.projectStages.length) {
                    const getProjectStageServiceFeePromises = [];
                    confirmedQuote.projectStages.map((projectStage, i) => {
                        getProjectStageServiceFeePromises.push(this.getProjectStageServiceFee(i));
                        return projectStage;
                    });
                    const getProjectStageServiceFeeResponses = await Promise.all(getProjectStageServiceFeePromises);
                    if (getProjectStageServiceFeeResponses) {
                        confirmedQuote.projectStages = confirmedQuote.projectStages.map((projectStage, i) => {
                            const isMaterial = ProjectStageTypeEnum.MATERIALS === projectStage.stageType;
                            const projectStageServiceFee = getProjectStageServiceFeeResponses[i];
                            const stageCostAndServiceFee = (projectStage.stageCost || projectStage.subTotal) + projectStageServiceFee;
                            const projectStageWithServiceFee = {
                                ...projectStage, projectStageServiceFee, stageCostAndServiceFee, isMaterial
                            };
                            if (projectStage.requiredDeposit
                                && [ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED].includes(projectStage.projectStageStatus.id)) {
                                this.requiredStagesToPay.push(projectStage.id);
                            }
                            return projectStageWithServiceFee;
                        });
                        this.projectStages = confirmedQuote.projectStages;
                    }
                }
                this.project = currentSelectedProject;
                this.project.confirmedQuote = confirmedQuote;
                this.setBillInformation();
            }
            else {
                this.$notify.error({
                    message: 'Project not found.'
                });
                this.routeToPropertyOverview();
            }
        },
        routeToPropertyOverview() {
            this.$router.push({ name: 'overview' });
        },
        async getProjectStageServiceFee(stageIndex) {
            const { currentSelectedProject } = this;
            const { confirmedQuote } = currentSelectedProject;
            const { projectStages } = confirmedQuote;
            const totalStageCost = await this.getTotalStageCost(projectStages);
            const totalServiceFee = await this.calculateServiceFee({ projectId: currentSelectedProject.id, projectStages });
            const total = confirmedQuote.projectStages[stageIndex].stageCost || confirmedQuote.projectStages[stageIndex].subTotal;
            const stageCostPercentage = total / totalStageCost;
            const stageServiceFee = totalServiceFee * stageCostPercentage;
            return parseFloat(stageServiceFee.toFixed(2));
        },
        async getProjectAccountBalance() {
            const { currentSelectedProject, user } = this;
            if (currentSelectedProject?.projectAccount) {
                await this.getUserCustomerAccountDetails({
                    userId: user.id,
                    accountId: currentSelectedProject?.projectAccount.accountId
                })
                    .then((response) => {
                    const { availableBalance } = response;
                    this.projectAccountBalance = availableBalance;
                })
                    .catch(() => { });
            }
        },
        setBillInformation() {
            const total = this.getTotalAmountToPay();
            const vat = total * 0.20;
            this.billInformation = {
                totalStageCost: total,
                totalAmountToPay: total,
                vat,
                totalAndVat: total + vat,
                totalPaidOrUnpaid: this.getTotalAmountToPay(true),
                paymentReference: this.getPaymentReference()
            };
        },
        getPaymentReference() {
            const { project } = this;
            return StringHelper.cleanPaymentReference(project?.refId || '');
        },
        getTotalAmountToPay(sumAll = false) {
            const { currentSelectedProject } = this;
            const { projectStages } = currentSelectedProject.confirmedQuote;
            const phases = this.selectedStagesToPay.length ? this.selectedStagesToPay : projectStages;
            const unpaidStages = phases.filter((stage) => this.unpaidStatuses.includes(stage.projectStageStatus.id));
            let stages = unpaidStages.map((projectStage) => {
                const { amount: rawTotalCost } = projectStage;
                return { rawTotalCost };
            });
            if (sumAll) {
                stages = projectStages.map((projectStage) => {
                    const { stageCostAndServiceFee: rawTotalCost } = projectStage;
                    return { rawTotalCost };
                });
            }
            let totalCostSumOfStages = 0;
            if (stages.length) {
                const totalCostPerStage = stages.map((stage) => stage.rawTotalCost);
                totalCostSumOfStages = totalCostPerStage.reduce((previousValue, currentValue) => {
                    return previousValue + currentValue;
                }, 0);
            }
            return totalCostSumOfStages;
        },
        back() {
            if (this.screen === 'auto-payment') {
                this.setScreen('overview');
            }
            if (this.screen === 'manual-payment') {
                this.setScreen('auto-payment');
            }
        },
        hasUnmetRequiredStageToPay() {
            const { requiredStagesToPay } = this;
            let hasUnmetRequired = false;
            let count = 0;
            this.selectedStagesToPay.forEach((projectStage) => {
                if (requiredStagesToPay.includes(projectStage.id)) {
                    count += 1;
                }
            });
            if (requiredStagesToPay.length !== count) {
                hasUnmetRequired = true;
            }
            return hasUnmetRequired;
        },
        hasNotSelectedStagesToPay() {
            return !this.selectedStagesToPay.length;
        },
        onHandleClick() {
            if (this.screen === 'overview') {
                if (this.hasNotSelectedStagesToPay()) {
                    this.$notify.error({
                        message: 'Please select which phase(s) you want to pay.'
                    });
                    return;
                }
                if (this.hasUnmetRequiredStageToPay()) {
                    this.$notify.error({
                        message: 'You need to at least select the required phase(s) to pay.'
                    });
                    return;
                }
                this.screen = 'auto-payment';
            }
            else {
                this.onConfirmPayment();
            }
        },
        onAfterSelectPaymentMethod(paymentForm) {
            this.paymentForm = paymentForm;
        },
        onConfirmPayment() {
            const { project, paymentForm, } = this;
            const { projectAccount } = project;
            const projectStages = this.selectedStagesToPay;
            const unpaidStages = projectStages.filter((stage) => this.unpaidStatuses.includes(stage.projectStageStatus.id));
            const stageData = unpaidStages.map((projectStage) => {
                const { projectStageServiceFee } = projectStage;
                return {
                    projectStageId: projectStage.id,
                    stageServiceFee: projectStageServiceFee
                };
            });
            this.isPaymentProcessing = true;
            paymentForm.destination.id = projectAccount.accountId;
            paymentForm.paymentReference = this.getPaymentReference();
            paymentForm.projectStageDeposits = stageData;
            delete paymentForm.projectStageId;
            if (!this.paymentForm.aspspId) {
                this.$notify.error({
                    title: 'Error Phase Deposit',
                    message: 'Please select a payment provider!'
                });
                this.isPaymentProcessing = false;
                return;
            }
            this.createAllProjectStageDeposit({ ...this.paymentForm }).then((response) => {
                if (response && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            })
                .catch((error) => {
                this.$notify.error({
                    title: 'Error Phase Deposit',
                    message: error && error.response && error.response.data ? error.response.data.message : 'Error occurred. Unable to send deposit stage from your account at this time. Please try again.'
                });
            })
                .finally(() => {
            });
        },
        onConfirm() {
            //   TEMPORARY
            this.setScreen('overview');
        },
        setScreen(newScreen) {
            this.screen = newScreen;
        },
        onRemoveMainButton() {
            this.removeMainButton = true;
        },
        closeDrawer() {
            this.setOpenEscrowDrawer(false);
        }
    },
});
