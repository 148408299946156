import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ProjectJobStatusEnum, ProjectStageStatusEnum, } from '@/core/packages/shared-library';
import { PAYMENTS_STORE } from '@/store/modules/payments';
import { PROJECT_STAGES_STORE } from '@/store/modules/project-stages';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    emits: ['set-screen', 'on-after-select-payment-method'],
    props: [
        'project',
        'bill'
    ],
    data() {
        return {
            bankSelected: '',
            bankOptions: [
                {
                    label: 'Barclay',
                    value: 'barclay'
                }
            ],
            unpaidStatuses: [ProjectStageStatusEnum.NOT_STARTED, ProjectStageStatusEnum.NOT_STARTED_POKED],
            loadBankData: false,
        };
    },
    computed: {
        ...mapGetters(PROJECT_STAGES_STORE, ['selectedStagesToPay']),
        ...mapGetters(PROJECTS_STORE, [
            'hasGetProjectFinished',
            'isProjectReadyToWork',
            'currentSelectedProject'
        ]),
        ...mapGetters(PAYMENTS_STORE, ['paymentProviders']),
        ...mapState(PAYMENTS_STORE, {
            paymentForm: (state) => state.paymentForm,
        }),
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'getUserCustomerAccount',
            'getUserCustomerAccountDetails',
            'getUserRatingsPageList',
            'createUserRating',
            'getUserRatingSummary'
        ]),
        ...mapActions(PAYMENTS_STORE, ['getPaymentProviders']),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'setSelectedProjectJobStatus']),
        showAccountDetails(selectedBank) {
            if (selectedBank.length) {
                this.loadBankData = true;
                this.paymentForm.aspspId = selectedBank;
                this.paymentForm.paymentAmount.value = parseFloat(this.bill.totalAmountToPay.toFixed(2));
                this.$emit('on-after-select-payment-method', {
                    ...this.paymentForm
                });
            }
        },
        async initialize() {
            await this.loadPaymentProviders();
        },
        async loadPaymentProviders() {
            const { project } = this;
            const { projectJobStatus } = project;
            if (projectJobStatus.id !== ProjectJobStatusEnum.COMPLETED) {
                await this.getPaymentProviders()
                    .catch(() => {
                    this.$notify.error({
                        message: 'Error fetching payment providers at the moment. Please try again.',
                    });
                });
            }
        },
        setScreen(screen) {
            this.$emit('set-screen', screen);
        },
    },
});
