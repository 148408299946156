<template>
    <div class="icon-status">
        <!-- This is in progress icon -->
        <inline-svg v-if="isinProgress" width="16px" height="16px" :src="require(`@/assets/icons/in-progress-icon.svg`)"></inline-svg>
        <!-- This is a red info icon -->
        <!-- <el-icon v-if="iconType === 'red-info'" :size="16" color="#FA4B00"><InfoFilled/></el-icon> -->
        <!-- This is a success yello icon -->
        <el-icon v-if="isCompleted" :size="16" color="#FFA500"><SuccessFilled/></el-icon>
        <!-- This if yellow info icon -->
        <el-icon v-if="hasRequiredDeposit" title="Deposit Required" :size="16" color="#FAA100"><InfoFilled/></el-icon>
        <!-- This is a warning icon -->
        <el-icon v-if="isWarningToReleasePayment" :size="16" color="#FA4B00"><WarningFilled/></el-icon>
        <!-- This is a not started icon -->
        <inline-svg v-if="IsNotStarted" width="16" height="16" :src="require(`@/assets/icons/status-not-started.svg`)"></inline-svg>
        <!-- <inline-svg width="16" height="16" v-if="status === 'in progress'" :src="require(`@/assets/icons/in-progress-icon.svg`)"></inline-svg> -->
    </div>
</template>
<script>

import {
  InfoFilled,
  SuccessFilled,
  WarningFilled
} from '@element-plus/icons';
import { defineComponent } from 'vue';

import { ProjectStageStatusEnum } from '@/core/packages/shared-library';

export default defineComponent({
  components: {
    InfoFilled,
    SuccessFilled,
    WarningFilled
  },
  props: ['phaseStatus', 'hasRequiredDeposit'],

  data() {
    return {
      ProjectStageStatusEnum
    };
  },

  computed: {
    IsNotStarted() {
      return this.phaseStatus === ProjectStageStatusEnum.NOT_STARTED || this.phaseStatus === ProjectStageStatusEnum.NOT_STARTED_POKED;
    },

    isinProgress() {
      return this.phaseStatus === ProjectStageStatusEnum.IN_PROGRESS;
    },

    isWarningToReleasePayment() {
      return this.phaseStatus === ProjectStageStatusEnum.IN_PROGRESS_POKED;
    },

    isCompleted() {
      return this.phaseStatus === ProjectStageStatusEnum.STAGE_COMPLETE;
    }

  },
});

</script>
<style lang="scss" scoped>
</style>
