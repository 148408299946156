<template>
    <el-form
      class="flex-column"
      ref="requestMeetingForm"
      :model="scheduleForm"
      label-position="top">
       <div class="form-container flex-column jc-sb">
        <section class="form-container__content" v-if="screenType === screen.DATA_OVERVIEW_SCREEN">
          <el-form-item
            label="Meeting preference"
            prop="preference">
          <el-radio-group v-model="scheduleForm.preference">
              <el-radio :label="preference.VIDEO_CALL">Video</el-radio>
              <el-radio :label="preference.IN_PERSON">In-person</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="Date & time" v-if="!hasData">
            <el-input
                @focus="setScreen(screen.SELECT_DATE_TIME_SCREEN)"
                placeholder="E.g. 10th Feb 2023 - 08:00">
                  <template #suffix>
                    <inline-svg width="17px" height="17px" :src="require(`@/assets/icons/date-icon.svg`)"></inline-svg>
                  </template>
              </el-input>
          </el-form-item>

          <div  v-if="hasData" class="flex-column">
            <DateAndTimeOverview
              :datesAndTimes="scheduleForm.schedule"
              @on-edit="onEdit"
              @on-delete-time-slot="onDeleteTimeSlot($event)"
              @on-delete-date="onDeleteDate($event)"/>
            <el-form-item
              label="Notes">
              <el-input placeholder="Text" type="textarea" v-model="scheduleForm.note" clearable></el-input>
            </el-form-item>

            <InviteEmailForm @on-invite="addToMemberList($event)"/>
            <div class="form-container__wrapper flex-row ai-c jc-sb" v-for="(recipient, index) in scheduleForm.recipients" :key="index">
              <p class="form-container__text default-text">{{recipient.email}}</p>
              <ActionItemComponent icon="trash-icon.svg" @call-to-action="removeEmailToList(index)"/>
            </div>
          </div>

        </section>
        <SelectMultipleDateAndTimeComponent
          :datesAndTimes="scheduleForm.schedule"
          v-if="screenType === screen.SELECT_DATE_TIME_SCREEN"
          @remove-existing-date="onDeleteDate($event)"
          @on-cancel="setScreen($event)"
          @on-confirm="setRequestData($event)"/>
        <section class="form-container__actions" v-if="screenType === screen.DATA_OVERVIEW_SCREEN">
          <Button :isActive="hasData" buttonText="send request" :loading="isSending" @handle-click="onRequest"/>
        </section>
      </div>
    </el-form>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DateAndTimeOverview from '@/core/components/common/calendar/DateAndTimeOverview.vue';
import SelectMultipleDateAndTimeComponent from '@/core/components/common/calendar/SelectMultipleDateAndTimeComponent.vue';
import InviteEmailForm from '@/core/components/common/forms/request-meeting/InviteEmailForm.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Button from '@/core/components/ui/Button.vue';
import { CALENDAR_EVENT_TYPE_PROJECT } from '@/core/constants';
import preference from '@/core/constants/calendar';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import screen from '@/core/constants/screen/request-meeting';
import StringHelper from '@/core/helpers/string.helper';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    SelectMultipleDateAndTimeComponent,
    Button,
    DateAndTimeOverview,
    InviteEmailForm,
    ActionItemComponent
  },
  emits: ['on-close'],
  data() {
    return {
      screen,
      preference,

      isSending: false,

      formData: {
        preference: '',
        schedule: null,
        note: '',
        recipients: []
      },
      memberInput: '',
      screenType: screen.DATA_OVERVIEW_SCREEN,
    };
  },
  computed: {
    ...mapGetters(USER_CALENDAR_STORE, ['dateWithTime', 'scheduleForm']),
    ...mapGetters(USERS_STORE, ['user']),

    hasData() {
      return this.scheduleForm.schedule != null && this.scheduleForm.schedule !== undefined;
    }
  },
  methods: {
    ...mapActions(USER_CALENDAR_STORE, ['setDateWithTime', 'createOrUpdateScheduleRequest']),

    setScreen(screenType) {
      this.screenType = screenType;
    },
    setRequestData($event) {
      this.scheduleForm.meta.id = this.$route.params?.projectRefId;
      this.scheduleForm.schedule = this.dateWithTime;
      this.scheduleForm.type = CALENDAR_EVENT_TYPE_PROJECT;
      this.setScreen($event);
    },
    addToMemberList(email) {
      this.$refs.requestMeetingForm.validate((valid) => {
        let isValid = valid;

        if (!StringHelper.isValidEmail(email)) {
          this.$notify.error({
            message: 'Please enter a valid email!'
          });
          isValid = false;
        }

        const emailExist = this.scheduleForm.recipients.find((emailObj) => emailObj.email === email);

        if (emailExist) {
          this.$notify.error({
            message: 'Email already exist!'
          });
          isValid = false;
        }

        if (isValid) {
          this.scheduleForm.recipients.push({
            email
          });
        }
      });
    },
    removeEmailToList(index) {
      this.scheduleForm.recipients.splice(index, 1);
    },
    onDeleteTimeSlot(dateAndTimeIndex) {
      const { dateIndex, timeIndex } = dateAndTimeIndex;

      const selectedDate = this.scheduleForm.schedule[dateIndex];
      const removedTimeSlot = selectedDate?.timeslots.splice(timeIndex, 1);

      this.setDateWithTime(this.scheduleForm.schedule);
      return removedTimeSlot;
    },
    onDeleteDate(dateIndex) {
      const removedDate = this.scheduleForm.schedule.splice(dateIndex, 1);
      this.setDateWithTime(this.scheduleForm.schedule);
      return removedDate;
    },
    async onRequest() {
      const formData = this.scheduleForm;
      const userId = this.user.id;

      const params = {
        userId,
        formData
      };

      this.isSending = true;

      await this.createOrUpdateScheduleRequest(params)
        .then(async (response) => {
          if (response) {
            this.$emit('on-close');
            this.$notify.success({
              title: 'Request sent.',
              message: 'Meeting request successfully sent.',
            });
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Request Invitation Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.isSending = false;
          this.resetCalendarState();
        });
    },
    onEdit() {
      this.setScreen(screen.SELECT_DATE_TIME_SCREEN);
    }
  },
});
</script>
<style lang="scss" scoped>
.el-form {
  height: 100%;
    .form-container {
      height: 100%;

      &__wrapper {
        width: 100%;
      }

      &__content {
        max-height: calc(100% - 5rem);
        height: 100%;
        overflow: auto;
      }

      &__actions {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
    .el-form-item {
        margin: 0;

       :deep(.el-form-item__label) {
           font-family: Mulish;
            font-size: 16px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
       }

       :deep(.el-input) {
          width: 100%;
          border-radius: 100px;
          height: 42px;
          background: rgba(12, 15, 74, 0.05);

          .el-input__inner {
            border-radius: 100px;
            outline:none;
            border: none;
            padding: 20px;
            background: transparent;
          }
       }

      :deep(.el-input__suffix) {
        right:20px;
        top: 5px;
        color:#111;
        display: inline-block !important;
      }
    }
}
</style>
