<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="request-meeting flex-column jc-sb">
        <div class="request-meeting__container flex-column">
          <div class="request-meeting__header flex-row ai-c jc-sb">
              <h2 class="request-meeting__title default-text">Request meeting</h2>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="request-meeting__body">
            <RequestMeetingForm @on-close="cancel"/>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';

import RequestMeetingForm from '@/core/components/common/forms/request-meeting/RequestMeetingForm.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';

export default defineComponent({
  components: {
    BaseModal,
    RequestMeetingForm
  },
  emits: ['on-cancel'],
  props: ['show'],
  data() {
    return {
    };
  },
  methods: {
    cancel() {
      this.$emit('on-cancel');
    }
  },
});
</script>
<style lang="scss" scoped>
 .request-meeting {
    min-width: 500px;
    min-height: 700px;
    position: relative;
    height: 700px;
    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }

    &__actions {
        width: 100%;
        height: auto;
    }

    &__button {
        height: 42px;
        width: 100%;
        border-radius: 8px;
        background: rgba(12, 15, 74, 0.05);
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-align: center;
        color: rgba(12, 15, 74, 0.5);
        border: none;
        text-transform: uppercase;

        &:hover {
            background: rgba(12, 15, 74, 0.05);
            color: rgba(12, 15, 74, 0.5);
        }
    }

    &__button.active {
        color: #FFF;
        background-color: #FFA500;

        &:hover {
          color: #FFF;
          background-color: #FFA500;
        }
    }
 }
</style>
