// import { CircleCloseFilled, WarningFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ProjectEscrowSidebarComponent from '@/modules/project-details-children/components/escrowSidebar/ProjectEscrowSidebarComponent.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    name: 'escrow-drawer',
    components: {
        // WarningFilled,
        // CircleCloseFilled,
        ProjectEscrowSidebarComponent
    },
    computed: {
        ...mapGetters(['isTradesperson']),
        ...mapGetters(PROJECTS_STORE, ['getOpenEscrowDrawer', 'enableProjectEscrowSidebar']),
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['setOpenEscrowDrawer']),
        closeDrawer() {
            this.setOpenEscrowDrawer(false);
        }
    }
});
