import { defineComponent } from 'vue';
import PhasePaymentComponent from '@/modules/project-details-children/components/escrowSidebar/PhasePaymentComponent.vue';
import WalletBalanceComponent from '@/modules/project-details-children/components/escrowSidebar/WalletBalanceComponent.vue';
export default defineComponent({
    components: {
        WalletBalanceComponent,
        PhasePaymentComponent
    },
    emits: ['on-selected-stages', 'on-remove-main-button'],
    props: ['projectStages', 'bill', 'projectAccountBalance'],
    methods: {
        onConfirm(selectedStages) {
            this.$emit('on-selected-stages', selectedStages);
        },
        onRemoveMainButton() {
            this.$emit('on-remove-main-button');
        }
    }
});
