<template>
    <div class="removable-single-data-item flex-row ai-c jc-c">
      <slot name="custom-slot"></slot>
      <span v-if="isRemovable" @click="onRemove"><el-icon :size="17" color="#0C0F4A"><CircleCloseFilled/></el-icon></span>
    </div>
</template>
<script>
import { CircleCloseFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CircleCloseFilled
  },
  emits: ['remove-item'],
  props: {
    index: {
      type: Number,
    },
    bgColor: {
      type: String,
      default: 'rgba(12, 15, 74, 0.05)'
    },
    isRemovable: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    onRemove() {
      this.$emit('remove-item', this.index);
    }
  },
});
</script>
<style lang="scss" scoped>
 .removable-single-data-item {
    background: v-bind('$props.bgColor');
    padding: 0.3rem 1rem;
    border-radius: 10px;
    position: relative;

    span {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
 }
</style>
