<template>
    <BoxSlotComponent title="Attachments" :edit="false" modalType="PAttachments">
        <el-card>
            <div class="details-container">
                <el-scrollbar>
                    <div class="document-list-container">
                        <div class="list" v-for="(projectAttachment, index) in project.projectAttachments" :key="index">
                            <div class="file">
                                <!-- <img src="@/assets/icons/pdf-icon-sample.svg" alt="sample icon"> -->
                                <p class="text-ellipsis">{{ projectAttachment.originalName }}</p>
                            </div>
                            <div class="date">
                                <p>{{ $filters.formatToDatetime(projectAttachment.dateCreated) }}</p>
                                <!-- <img src="@/assets/icons/more-icon.svg" alt="more icon"> -->
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </el-card>
    </BoxSlotComponent>
</template>
<script>
import { defineComponent } from 'vue';

import BoxSlotComponent from '@/core/components/common/box-cards/BoxSlotComponent.vue';

export default defineComponent({
  components: {
    BoxSlotComponent
  },

  props: ['project'],

  data() {
    return {
      projectAttachments: []
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
    }
  }

});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

    .el-card.is-always-shadow {
      box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
    }
  .el-card {
    height:194px;
    max-height: 194px;
    padding: 0;
    position: relative;
    padding:1.5rem 2rem 1.5rem 1.5rem;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    border:none;

    :deep(.el-card__body) {
        width:100%;
        padding:0;
    }
  }
  .details-container {
    display:flex;
    flex-direction: column;
    width:100%;
    gap:1rem;
    height:100%;

    .el-scrollbar {
        .document-list-container {
            display: flex;
            flex-direction: column;

            .list {
                display:flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .file {
                    display: flex;
                    gap: 1rem;
                    align-items: center;

                    p {
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 28px;
                        letter-spacing: 0.5px;
                        text-align: left;
                        color: #0C0F4A;
                        margin: 0;
                    }
                }
                .date {
                    display: flex;
                    gap: 2rem;
                    align-items: center;
                    p {
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 28px;
                        letter-spacing: 0.5px;
                        text-align: right;
                        color: rgba(12, 15, 74, 0.5);
                    }
                }
            }
        }
    }
  }

@include media-xs-max-width() {
.details-container {
  display:flex;
  flex-direction: column;
  width:100%;
  gap:1rem;
  height:100%;

    .el-scrollbar {
      .document-list-container {
        display: flex;
        flex-direction: column;
        .list {
          .file {
            p {
              font-size: 12px;
            }
          }
          .date {
            p {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
