<template>
    <BoxSlotComponent title="Description" :edit="isProjectOwner" modalType="PDescription">
        <el-card>
            <div class="details-container">
                <el-scrollbar>
                    <p class="description">
                      {{ description }}
                    </p>
                </el-scrollbar>
            </div>
        </el-card>
    </BoxSlotComponent>
</template>
<script>
import { mapGetters } from 'vuex';

import BoxSlotComponent from '@/core/components/common/box-cards/BoxSlotComponent.vue';

export default {
  components: {
    BoxSlotComponent
  },

  props: ['description'],

  computed: {
    ...mapGetters(['isProjectOwner']),
  }
};
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

    .el-card.is-always-shadow {
      box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
    }
  .el-card {
    height:452px;
    max-height: 452px;
    padding: 0;
    position: relative;
    padding:1.5rem 2rem 1.5rem 1.5rem;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    border:none;

    :deep(.el-card__body) {
        width:100%;
        padding:0;
    }
  }
  .details-container {
    display:flex;
    flex-direction: column;
    width:100%;
    gap:1rem;
    height:100%;

    .el-scrollbar {
        .description {
            font-family: Mulish;
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.10000000149011612px;
            text-align: left;
            color: #0C0F4A;
            margin: 0;
        }
    }
  }

  @include media-xs-max-width() {
    .details-container {
      .el-scrollbar {
          .description {
              font-size: 12px;
          }
      }
    }
  }
</style>
