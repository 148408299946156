import { mapActions, mapGetters } from 'vuex';
import BoxSlotComponent from '@/core/components/common/box-cards/BoxSlotComponent.vue';
import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
import PropertyDetailsOverviewWidget from '@/modules/project-post-overview/components/PropertyDetailsOverviewWidget.vue';
import { PROJECTS_ROUTE } from '@/modules/projects/routes';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default {
    components: {
        // ArrowRightBold,
        BoxSlotComponent,
        PropertyDetailsOverviewWidget
    },
    props: ['property', 'status'],
    data() {
        return {
            projectData: null,
            isQuoteAccepted: false,
            isQuoteConfirmed: false,
            isQuoteInProgress: false,
            isQuoteCompleted: false,
        };
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(['isProjectOwner']),
        epcData() {
            let epcData = null;
            if (this.property?.epcData) {
                epcData = JSON.parse(this.property?.epcData);
            }
            return epcData;
        },
        bedroomCount() {
            return this.property?.beds || 0;
        },
        showAddress() {
            if (this.isQuoteAccepted || this.isQuoteConfirmed || this.isQuoteInProgress || this.isQuoteCompleted) {
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId']),
        async initialize() {
            const { projectRefId } = this.$route.params;
            try {
                const projectData = await this.getProjectByRefId(projectRefId);
                this.projectData = projectData;
                const { projectQuotes: [estimateForm] } = projectData;
                if (estimateForm) {
                    this.isQuoteAccepted = estimateForm.projectQuoteStatus?.id === ProjectQuoteStatusEnum.QUOTE_ACCEPTED;
                    this.isQuoteConfirmed = estimateForm.projectQuoteStatus?.id === ProjectQuoteStatusEnum.QUOTE_CONFIRMED;
                    this.isQuoteInProgress = estimateForm.projectQuoteStatus?.id === ProjectQuoteStatusEnum.IN_PROGRESS;
                    this.isQuoteCompleted = estimateForm.projectQuoteStatus?.id === ProjectQuoteStatusEnum.COMPLETED;
                }
            }
            catch (error) {
                this.$notify.error({
                    message: error && error?.message ? error?.message : 'Project not found.'
                });
                this.$router.push({ name: PROJECTS_ROUTE });
            }
        },
    },
};
