<template>
  <div class="select-multiple-date flex-column jc-sb" :class="{'static-height': screenType === TIME}">
    <section class="select-multiple-date__wrapper flex-column" v-if="screenType === DATE">
      <div class="select-multiple-date__wrapper grid">
        <RemovableItem
          v-for="(date, index) in formattedDates"
          :key="index"
          :index="index"
          @remove-item="onRemoveItem($event)">
          <template #custom-slot>
            <p class="select-multiple-date__text default-text">{{date}}</p>
          </template>
        </RemovableItem>
      </div>
      <div class="select-multiple-date__wrapper flex-row ai-c jc-c">
        <el-date-picker
          v-model="selectedDates"
          type="dates"
          placeholder="Pick maximum of 3 dates"
          :format="DATE_FORMAT"
          :value-format="SYSTEM_DATE_FORMAT"
          :disabledDate="disabledDate"
        />
      </div>
    </section>
    <section class="select-multiple-date__wrapper flex-column" style="height: 100%;" v-if="screenType === TIME">
      <el-scrollbar>
        <div class="select-multiple-date__scrollbar-wrapper flex-column">
          <div class="select-multiple-date__wrapper flex-column"
            v-for="(date, dateIndex) in formattedDates" :key="dateIndex" id="date">
            <p class="select-multiple-date__text bold default-text" style="text-align: left;">
              {{ date }}
            </p>
            <div class="select-multiple-date__wrapper grid">
              <RemovableItem
                class="select-multiple-date__time-item cursor-pointer"
                v-for="(time, timeIndex) in times.times()"
                :key="timeIndex"
                :id="'time-' + timeIndex"
                :isRemovable="false"
                @click="onTimeSlotSelect(dateIndex, timeIndex)"
                :class="{'active': isActiveTimeSlot(dateIndex, timeIndex)}">
                <template #custom-slot>
                  <p class="select-multiple-date__text default-text">{{time}}</p>
                </template>
              </RemovableItem>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </section>
    <section class="select-multiple-date__wrapper flex-row ai-c jc-sa">
      <Button :isTransparent="true" buttonText="cancel" @handle-click="onCancel"/>
      <Button :isActive="isReadyForNext" :buttonText="screenType === DATE ? 'next' : 'confirm'" :loading="loading" @handle-click="onConfirm"/>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import Button from '@/core/components/ui/Button.vue';
import RemovableItem from '@/core/components/ui/RemovableItem.vue';
import { DATE_FORMAT, DISPLAY_DATE_FORMAT, SYSTEM_DATE_FORMAT } from '@/core/constants';
import screen from '@/core/constants/screen/request-meeting';
import times from '@/core/helpers/date-time.helper';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';

const DATE = 'date';
const TIME = 'time';

export default defineComponent({
  components: {
    Button,
    RemovableItem
  },
  props: ['datesAndTimes'],
  emits: ['on-cancel', 'on-confirm', 'remove-existing-date'],
  data() {
    return {
      DATE,
      TIME,

      times,

      screen,
      DATE_FORMAT,
      SYSTEM_DATE_FORMAT,

      selectedDates: [],
      selectedDateWithTime: [],
      isReadyForNext: false,
      loading: false,
      screenType: DATE,
      disabledDate(date) {
        return moment(date).utc() < moment().startOf('day');
      },
    };
  },
  created() {
    this.extractDatesAndTimes();
  },
  computed: {
    formattedDates() {
      return this.selectedDates.map((date) => this.$filters.formatToDate(date, DISPLAY_DATE_FORMAT));
    },
    isEditing() {
      return this.datesAndTimes !== null;
    }
  },
  watch: {
    selectedDates(newDates) {
      if (newDates.length > 3) {
        this.selectedDates = newDates.slice(0, 3);
      }
      if (newDates.length && this.screenType === DATE) {
        this.isReadyForNext = true;
      } else {
        this.isReadyForNext = false;
      }
    },
  },
  methods: {
    ...mapActions(USER_CALENDAR_STORE, ['setDateWithTime']),

    onRemoveItem(index) {
      this.selectedDates.splice(index, 1);
    },
    onCancel() {
      this.$emit('on-cancel', this.screen.DATA_OVERVIEW_SCREEN);
    },
    setScreen(type) {
      this.screenType = type;
    },
    onConfirm() {
      if (this.screenType === DATE) {
        this.setScreen(TIME);
        return;
      }
      if (this.selectedDateWithTime.length && this.screenType === TIME) {
        const allTimesSelected = this.selectedDateWithTime.every((date) => date.timeslots !== null && date.timeslots.length > 0);

        if (allTimesSelected && this.selectedDateWithTime.length === this.selectedDates.length) {
          this.setDateWithTime(this.selectedDateWithTime);
          this.$emit('on-confirm', this.screen.DATA_OVERVIEW_SCREEN);
        } else {
          this.$notify.error({
            message: 'Please select at least 1 and up to 3 time slots for each date.'
          });
        }
      } else {
        this.$notify.error({
          message: 'Please select at least 1 and up to 3 time slots for each date.'
        });
      }
    },
    onTimeSlotSelect(dateIndex, timeIndex) {
      const selectedDate = this.selectedDates[dateIndex];
      // const selectedTime = this.times[timeIndex];
      const selectedTime = times.times()[timeIndex];

      const existingDate = this.selectedDateWithTime.find((dateObj) => dateObj.date === selectedDate);

      if (existingDate) {
        if (existingDate.timeslots.length < 3 && !existingDate.timeslots.some((timeObj) => timeObj.value === selectedTime)) {
          existingDate.timeslots.push({ value: selectedTime });
        } else if (existingDate.timeslots.some((timeObj) => timeObj.value === selectedTime)) {
          existingDate.timeslots = existingDate.timeslots.filter((time) => time.value !== selectedTime);
        }
      } else {
        this.selectedDateWithTime.push({
          date: selectedDate,
          timeslots: [{ value: selectedTime }],
        });
      }
    },
    isActiveTimeSlot(dateIndex, timeIndex) {
      const selectedDate = this.selectedDates[dateIndex];
      const selectedTime = times.times()[timeIndex];

      const existingDate = this.selectedDateWithTime.find((dateObj) => dateObj.date === selectedDate);

      return existingDate && existingDate.timeslots.some((timeObj) => timeObj.value === selectedTime);
    },
    extractDatesAndTimes() {
      if (this.isEditing) {
        this.selectedDates = this.datesAndTimes.map((item) => item.date);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.select-multiple-date {
  gap: 1rem;
  height: 100%;

  &__text {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-wrap: nowrap;
  }

  &__wrapper {
    width: 100%;
  }
  &__wrapper.grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  &__time-item.active {
    border: 1px solid #FFA500;
  }
}
.select-multiple-date.static-height {
  height: calc(100% - 6.5rem);
}
</style>
