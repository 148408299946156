<template>
    <div class="date-time-item flex-column">
       <div class="date-time-item__wrapper flex-row ai-c jc-sb">
          <p class="date-time-item__text default-text">{{$filters.formatToDate(dateAndTime.date, DISPLAY_DATE_FORMAT)}}</p>
          <ActionItemComponent
            v-if="!isNotEditable"
            icon="trash-icon.svg"
            fontColor="#FFA500"
            @call-to-action="onDeleteDate(dateIndex)"/>
        </div>
        <div class="date-time-item flex-row ai-c jc-fs">
          <RemovableItem
            class="date-time-item__item"
            :class="{'cursor-pointer': isClickable, 'active': (timeIndex === selectedDateAndTimeIndex?.timeIndex && dateIndex === selectedDateAndTimeIndex?.dateIndex) || (time?.status && time?.status === scheduleConstants.STATUS_CONFIRMED)}"
            v-for="(time, timeIndex) in dateAndTime.timeslots"
            :key="timeIndex"
            :index="timeIndex"
            :isRemovable="isRemovable"
            @click="onTimeSlotSelect(dateIndex, timeIndex)"
            @remove-item="onRemoveTimeSlot($event)">
            <template #custom-slot>
                <p class="select-multiple-date__text default-text">{{time?.value}}</p>
            </template>
         </RemovableItem>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import RemovableItem from '@/core/components/ui/RemovableItem.vue';
import { DISPLAY_DATE_FORMAT } from '@/core/constants';
import scheduleConstants from '@/core/constants/calendar';

export default defineComponent({
  components: {
    ActionItemComponent,
    RemovableItem
  },
  emits: ['on-time-slot-select', 'on-delete-time-slot', 'on-delete-date'],
  props: ['dateAndTime', 'dateIndex', 'isNotEditable', 'isClickable', 'selectedDateAndTimeIndex'],
  data() {
    return {
      DISPLAY_DATE_FORMAT,
      scheduleConstants
    };
  },
  computed: {
    isRemovable() {
      if (this.isNotEditable) {
        return false;
      }
      return true;
    }
  },
  created() {
  },
  methods: {
    onTimeSlotSelect(dateIndex, timeIndex) {
      const dateAndTimeIndex = {
        dateIndex,
        timeIndex
      };
      if (this.isClickable) {
        this.$emit('on-time-slot-select', dateAndTimeIndex);
      }
    },
    onRemoveTimeSlot(timeIndex) {
      const { dateIndex } = this;

      const dateAndTimeIndex = {
        dateIndex,
        timeIndex
      };
      this.$emit('on-delete-time-slot', dateAndTimeIndex);
    },
    onDeleteDate(dateIndex) {
      this.$emit('on-delete-date', dateIndex);
    }
  },
});
</script>
<style lang="scss" scoped>
.date-time-item {
  width: 100%;
  &__wrapper {
    width: 100%;
 }
 &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 156.25% */
    letter-spacing: 0.25px;
 }
 &__item.active {
   border: 1px solid #FFA500;
 }
}
</style>
